import { createMachine } from '@minutemailer/state';
import request from '@lib/request';
import i18n from '@lib/i18n';
import flashNotification from '@lib/flashNotification';

export default createMachine('app', {
    state: {
        connected: true,
        current: 'idle',
        info: null,
        error: false,
        i18n: null,
        history: null,
        drafts: [],
        version: null,
        notification: null,
    },

    transitions: {
        fetchInfo: { from: 'idle', to: 'fetching' },
        infoFetched: { from: 'fetching', to: 'idle' },
        infoFailed: { from: 'fetching', to: 'error' },
        logout: { from: 'idle', to: 'logging-out' },
        logoutSuccess: { from: 'logging-out', to: 'guest' },
        logoutFailure: { from: 'logging-out', to: 'logout-error' },
        loading: { from: 'idle', to: 'loading' },
        doneLoading: { from: 'loading', to: 'idle' },
        redirect: { from: 'idle', to: 'redirecting' },
        redirected: { from: 'redirecting', to: 'idle' },
        switchTeam: { from: 'idle', to: 'switching-team' },
        switchedTeam: { from: 'switching-team', to: 'idle' },
        deleteTeam: { from: 'idle', to: 'deleting-team' },
        teamDeleted: { from: 'deleting-team', to: 'idle' },
        deleteTeamFailed: { from: 'deleting-team', to: 'idle' },
        addDraft: { from: 'idle', to: 'adding-draft' },
        draftAdded: { from: 'adding-draft', to: 'idle' },
        removeDraft: { from: 'idle', to: 'removing-draft' },
        draftRemoved: { from: 'removing-draft', to: 'idle' },
        cancelAccount: { from: 'idle', to: 'cancelling-account' },
        cancelledAccount: { from: 'cancelling-account', to: 'loading' },
        cancelFailed: { from: 'cancelling-account', to: 'cancel-failed' },
        cancelReset: { from: 'cancel-failed', to: 'idle' },
        resendConfirmationLink: { from: 'idle', to: 'resending' },
        confirmationLinkSent: { from: 'resending', to: 'idle' },
        createPortal: { from: 'idle', to: 'creating-portal' },
        portalCreated: { from: 'creating-portal', to: 'idle' },
        createCheckout: { from: 'idle', to: 'creating-checkout' },
        checkoutCreated: { from: 'creating-checkout', to: 'idle' },
        disconnect: { from: 'idle', to: 'disconnected' },
        reconnected: { from: 'disconnected', to: 'idle' },
    },

    handlers: {
        onFetching() {
            request('info')
                .then(({ notifications, ...response }) => {
                    const notificationsMachine =
                        this.getMachine('notifications');
                    const temporaryNotifications = notifications.filter(
                        (n) => n.severity === 'temporary',
                    );
                    const permanentNotification = notifications.find(
                        (n) => n.severity !== 'temporary',
                    );

                    if (temporaryNotifications.length) {
                        temporaryNotifications.forEach((n) => {
                            notificationsMachine.addNotification(
                                n.title,
                                n.message,
                            );
                        });
                    }

                    try {
                        const flashNotifications = JSON.parse(
                            window.sessionStorage.getItem('notifications') ||
                                '[]',
                        );

                        if (flashNotifications.length > 0) {
                            flashNotifications.forEach((notification) => {
                                notificationsMachine.addNotification(
                                    notification.title,
                                    notification.message,
                                );
                            });

                            window.sessionStorage.removeItem('notifications');
                        }
                    } catch (e) {
                        console.error(e);
                    }

                    const key = `openActivities-${response.user.token}-${response.team.id}`;
                    const drafts = JSON.parse(
                        window.localStorage.getItem(key) || '[]',
                    );

                    this.infoFetched({
                        info: response,
                        version: response.version,
                        notification: permanentNotification,
                        drafts,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.infoFailed({ error });
                });
        },

        onLoggingOut() {
            window.location.href = '/u/logout';
        },

        onRedirecting(url) {
            if (!this.state.history) {
                this.redirected();

                return;
            }

            const ui = this.getMachine('ui');

            ui.closeModal();

            this.state.history.push(url);
            this.redirected();
        },

        onSwitchingTeam(teamId, force) {
            const sameTeam = teamId === this.state.info.team.id && !force;

            if (!teamId || sameTeam) {
                this.switchedTeam();

                return;
            }

            request(`switch-team/${teamId}`, null, 'POST')
                .then(() => {
                    window.location.reload();
                })
                .catch((error) => {
                    console.error(error);
                    this.switchedTeam();
                });
        },

        onDeletingTeam(team_name) {
            const t = i18n('ui');

            request(`team`, { team_name }, 'DELETE')
                .then(() => {
                    flashNotification(
                        t('deleteTeamSuccessTitle', { name: team_name }),
                        t('deleteTeamSuccessMessage', { name: team_name }),
                    );
                    window.location.reload();
                })
                .catch((error) => {
                    const notifications = this.getMachine('notifications');

                    notifications.addNotification(
                        t('deleteTeamFailedTitle', { name: team_name }),
                        t('deleteTeamFailedMessage', { name: team_name }),
                    );

                    console.error(error);
                    this.deleteTeamFailed();
                });
        },

        onAddingDraft(data) {
            const index = this.state.drafts.findIndex(
                ({ id }) => id === data.id,
            );

            if (index > -1) {
                this.draftAdded();

                return;
            }

            const drafts = [data, ...this.state.drafts].slice(0, 3);
            const key = `openActivities-${this.state.info.user.token}-${this.state.info.team.id}`;

            window.localStorage.setItem(key, JSON.stringify(drafts));

            this.draftAdded({ drafts });
        },

        onRemovingDraft(id, redirect = false) {
            const index = this.state.drafts.findIndex(
                (draft) => draft.id === id,
            );

            if (index < 0) {
                this.draftRemoved();

                return;
            }

            const key = `openActivities-${this.state.info.user.token}-${this.state.info.team.id}`;
            const drafts = [...this.state.drafts];

            drafts.splice(index, 1);

            window.localStorage.setItem(key, JSON.stringify(drafts));

            this.draftRemoved({ drafts });

            if (this.state.history && redirect) {
                const ui = this.getMachine('ui');

                ui.closeModal();

                this.state.history.push(redirect);
            }
        },

        onCancellingAccount(password) {
            request('u/settings/account', { password }, 'DELETE')
                .then(() => {
                    this.cancelledAccount();
                    window.location.href = '/';
                })
                .catch((ex) => {
                    this.cancelFailed({
                        error: {
                            status: ex.status,
                            response: ex.response,
                        },
                    });
                });
        },

        onResending() {
            const notifications = this.getMachine('notifications');

            request('u/settings/account/resend', {}, 'POST')
                .then(() => {
                    const t = i18n('pages/settings/email');

                    notifications.addNotification(
                        t('addedEmailNotificationTitle'),
                        t('addedEmailNotificationContent', {
                            email: this.state.info.user.email,
                        }),
                    );
                })
                .catch(console.error)
                .finally(() => this.confirmationLinkSent());
        },

        onCreatingPortal() {
            request(
                'stripe/create-portal',
                { return_url: window.location.href },
                'POST',
            )
                .then((url) => {
                    window.location.href = url;
                })
                .finally(() => this.portalCreated());
        },

        onCreatingCheckout(args) {
            request('stripe/create-checkout', args, 'POST')
                .then((data) => {
                    const stripe = new Stripe(process.env.STRIPE_KEY);

                    stripe
                        .redirectToCheckout({
                            sessionId: data,
                        })
                        .then(console.log);
                })
                .finally(() => this.checkoutCreated({ product: null }));
        },
    },
});
